import React from 'react'
import { Link } from 'gatsby'

import * as style from "./index.module.css";

export default class Header extends React.Component {

  constructor(props) {
    super(props)
    this.handleClickMobileMenuOpen = this.handleClickMobileMenuOpen.bind(this);
    this.handleClickMobileMenuClose = this.handleClickMobileMenuClose.bind(this);
    this.handleClickMobileMenu = this.handleClickMobileMenu.bind(this);
    this.state = {
      mobileMenu: false,
    }
  }

  handleClickMobileMenuOpen(e) {
    e.preventDefault()
    this.setState({
      mobileMenu: true,
    })
  }

  handleClickMobileMenuClose(e) {
    e.preventDefault()
    this.setState({
      mobileMenu: false,
    })
  }

  handleClickMobileMenu(e) {
    this.setState({
      mobileMenu: false,
    })
  }

  render() {
    const menuActive = this.state.mobileMenu;
    const menuClass = menuActive ? `${style.menuStateOpen}` : "";
    const fullscreen = (this.props.fullscreen && !menuActive);
    const logo = fullscreen ? "/images/surventure-logo-white-plain.svg" : "/images/surventure-logo-plain.svg";
    return (
      <header className={menuClass}>
        <div className={fullscreen ? style.headerHome : style.header}>
          <Link to="/" className={style.logoContainer}><img className={style.logo} src={logo} alt="Logo Surventure campers" /></Link>
          <div className={style.menu}>
            <nav className={style.navigationContainer}>
              <Link className={style.navigationItem} to="/" activeClassName={style.navigationItemActive}>Home</Link>
              <Link className={style.navigationItem} activeClassName={style.navigationItemActive} to="/modellen/" partiallyActive={true}>Modellen</Link>
              <Link className={style.navigationItem} activeClassName={style.navigationItemActive} to="/projecten/" partiallyActive={true}>Portfolio</Link>
              <Link className={style.navigationItem} activeClassName={style.navigationItemActive} to="/over/" partiallyActive={true}>Over ons</Link>
              <Link className={style.navigationItem} activeClassName={style.navigationItemActive} to="/contact/" partiallyActive={true}>Contact</Link>
            </nav>
          </div>
          <button className={style.menuOpen} onClick={this.handleClickMobileMenuOpen}>
            <div className={style.circle}>Menu</div>
          </button>
          <button className={style.menuClose} onClick={this.handleClickMobileMenuClose}>
            <div className={style.circleAlt}>Sluit</div>
          </button>
        </div>
      </header>
    )
  }
}
