import React from 'react'

import * as style from "./index.module.css";

const Footer = ({ rental }) => (
  <footer id="footer" className={style.main}>
    {rental &&
      <div>
        <h1 className={style.title}>Wilt u een camper huren?</h1>
        <h2>Neem contact op:</h2>
        <p>
          <a className={style.link} href="mailto:verhuur@surventure.nl">verhuur@surventure.nl</a><br/>
          <a className={style.link} href="tel:+31629133956‬">06 29 133 956‬</a>
        </p>
      </div>
    }
    {!rental &&
      <div>
        <h1 className={style.title}>Benieuwd wat we voor u kunnen maken?</h1>
        <h2>Neem contact op:</h2>
        <p>
          <a className={style.link} href="mailto:info@surventure.nl">info@surventure.nl</a><br/>
          {/* <a className={style.link} href="tel:+31644628106">06 44 628 106</a> */}
        </p>
      </div>
    }
    <div className={style.iconContainer}>
      <a className={style.icon} target="_blank" rel="noopener noreferrer" href="https://facebook.com/surventure"><img src="/images/icons/facebook.svg" height="30" width="30" title="Volg ons op Facebook" alt="Facebook" /></a>
      <a className={style.icon} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC3PnMKeqogr7-lG7GEklQ-g"><img src="/images/icons/youtube.svg" height="30" width="30" title="Bekijk ons Youtube-kanaal" alt="Youtube" /></a>
      <a className={style.icon} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/surventure"><img src="/images/icons/instagram.svg" height="25" width="25" title="Volgs ons op Instagram" alt="Instagram" /></a>
    </div>
  </footer>
)

export default Footer
